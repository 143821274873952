import { CrmDictionary } from 'common-module/core/types';
import { crmSafeListByIDs } from 'common-module/list';

import { CartsApiService } from '~/api/carts/carts-api.service';
import { DocumentsApiService } from '~/api/documents/documents-api.service';
import { PatientsApiService } from '~/api/patient/patients-api.service';
import { BaseProductModel } from '~/api/products/base-product.model';
import { ProductsApiService } from '~/api/products/products-api.service';
import { BaseRecordModel } from '~/api/record/base-record.model';
import { RecordApiService } from '~/api/record/record-api.service';
import { TransactionsApiService } from '~/api/transactions/transactions-api.service';
import { UserApiService } from '~/api/user/user-api.service';

export const MAX_POPULATE_CHUNK_LENGTH = 80;
export const MAX_PATIENTS_POPULATE_CHUNK_LENGTH = 20;
export const MAX_DOCUMENTS_POPULATE_CHUNK_LENGTH = 20;

export const safeListUsersByIDs = (
  endpoint: UserApiService,
  ids: string[],
  additionalParams?: CrmDictionary,
) =>
  crmSafeListByIDs(
    (params) => endpoint.listUsersData({ ...params, ...additionalParams }),
    ids,
    MAX_POPULATE_CHUNK_LENGTH,
  );

export const safeListPatientsByIDs = (
  endpoint: PatientsApiService,
  ids: string[],
  additionalParams?: CrmDictionary,
) =>
  crmSafeListByIDs(
    (params) => endpoint.listData({ ...params, ...additionalParams }),
    ids,
    MAX_PATIENTS_POPULATE_CHUNK_LENGTH,
  );

export const safeListProductsByIDs = <Product extends BaseProductModel>(
  endpoint: ProductsApiService<Product>,
  ids: string[],
  additionalParams?: CrmDictionary,
) =>
  crmSafeListByIDs(
    (params) => endpoint.listData({ ...params, ...additionalParams }),
    ids,
    MAX_POPULATE_CHUNK_LENGTH,
  );

export const safeListCartsByIDs = (
  endpoint: CartsApiService,
  ids: string[],
  additionalParams?: CrmDictionary,
) =>
  crmSafeListByIDs(
    (params) => endpoint.listData({ ...params, ...additionalParams }),
    ids,
    MAX_POPULATE_CHUNK_LENGTH,
  );

export const safeListTransactionsByIDs = (
  endpoint: TransactionsApiService,
  ids: string[],
  additionalParams?: CrmDictionary,
) =>
  crmSafeListByIDs(
    (params) => endpoint.listData({ ...params, ...additionalParams }),
    ids,
    MAX_POPULATE_CHUNK_LENGTH,
  );

export const safeListRecordsByIDs = <Record extends BaseRecordModel>(
  endpoint: RecordApiService<Record>,
  ids: string[],
  additionalParams?: CrmDictionary,
) =>
  crmSafeListByIDs(
    (params) => endpoint.listData({ ...params, ...additionalParams }),
    ids,
    MAX_PATIENTS_POPULATE_CHUNK_LENGTH,
  );

export const safeListDocumentsByIDs = (
  endpoint: DocumentsApiService,
  ids: string[],
  additionalParams?: CrmDictionary,
) =>
  crmSafeListByIDs(
    (params) => endpoint.listData({ ...params, ...additionalParams }),
    ids,
    MAX_DOCUMENTS_POPULATE_CHUNK_LENGTH,
  );

import { toArray, joinArrayProperties, crmCollectResponse, CrmConfigService } from 'common-module/core';
import { get, set, chunk, uniq, compact, flattenDeep } from 'lodash-es';
import { forkJoin } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import * as i0 from '@angular/core';
import { inject, Component, ChangeDetectionStrategy, Input, HostBinding, NgModule, Injectable } from '@angular/core';
import { CrmCompositeComponent, CrmGeneralCompositeComponent, CrmGeneralCompositeContentBodyComponent, CrmCompositeStateService, CrmCompositeProvider } from 'common-module/composite';
import { CrmTableComponent } from 'common-module/table';
const crmPopulateResponse = config => {
  const configs = toArray(config);
  return switchMap(response => {
    const requests = configs.reduce((result, {
      list$,
      propertyConfig
    }, index) => {
      const values = toArray(propertyConfig).reduce((res, {
        property
      }) => {
        const mapped = response.data.reduce((mapResult, data) => {
          const value = get(data, joinArrayProperties(property));
          if (Array.isArray(value)) {
            mapResult.push(...value);
          } else {
            mapResult.push(value);
          }
          return mapResult;
        }, []);
        return res.concat(mapped);
      }, []);
      result[index] = list$(values);
      return result;
    }, {});
    return forkJoin(requests).pipe(map(responses => {
      const data = response.data.map(dataToMap => {
        configs.forEach(({
          propertyConfig
        }, index) => {
          toArray(propertyConfig).forEach(propConfig => {
            const {
              property,
              targetProperty,
              findProperty = '_id'
            } = propConfig;
            const value = get(dataToMap, joinArrayProperties(property));
            if (Array.isArray(value)) {
              set(dataToMap, joinArrayProperties(targetProperty), value.map(prop => responses[index].find(dataToFind => get(dataToFind, joinArrayProperties(findProperty)) === prop)));
            } else {
              set(dataToMap, joinArrayProperties(targetProperty), responses[index].find(dataToFind => get(dataToFind, joinArrayProperties(findProperty)) === value));
            }
          });
        });
        return dataToMap;
      });
      return {
        ...response,
        data
      };
    }));
  });
};
const crmFillIn = (property, values) => {
  return values.reduce((result, value, idx) => {
    result[`[${property}][$in][${idx}]`] = value;
    return result;
  }, {});
};
const crmSafeListByIDs = (list$, ids, maxCount) => crmCollectResponse(arrayChunk => list$({
  ...crmFillIn('_id', arrayChunk),
  limit: arrayChunk.length
}), chunk(uniq(compact(ids)), maxCount)).pipe(map(response => flattenDeep(response)));
class CrmListComponent extends CrmCompositeComponent {
  constructor() {
    super(...arguments);
    this.hostClass = 'crm-list';
    this.configService = inject(CrmConfigService);
  }
  onConfigUpdate(config) {
    const {
      header
    } = {
      ...this.configService.getConfig('modules.list'),
      ...config
    };
    this.headerConfig = header;
  }
  onInitFromRouteData(routeParams, routeData) {
    super.onInitFromRouteData(routeParams, routeData);
    this.tableProvider = this.dataProvider.tableProvider;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmListComponent_BaseFactory;
      return function CrmListComponent_Factory(t) {
        return (ɵCrmListComponent_BaseFactory || (ɵCrmListComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmListComponent)))(t || CrmListComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmListComponent,
      selectors: [["crm-list"]],
      hostVars: 2,
      hostBindings: function CrmListComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        tableProvider: "tableProvider"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 2,
      consts: [[3, "headerConfig"], [1, "crm-list__body"], [3, "dataProvider"]],
      template: function CrmListComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "crm-general", 0)(1, "crm-general-content-body")(2, "div", 1);
          i0.ɵɵelement(3, "crm-table", 2);
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵproperty("headerConfig", ctx.headerConfig);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("dataProvider", ctx.tableProvider);
        }
      },
      dependencies: [CrmGeneralCompositeComponent, CrmGeneralCompositeContentBodyComponent, CrmTableComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmListComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmGeneralCompositeComponent, CrmGeneralCompositeContentBodyComponent, CrmTableComponent],
      template: "<crm-general [headerConfig]=\"headerConfig\">\r\n  <crm-general-content-body>\r\n    <div class=\"crm-list__body\">\r\n      <crm-table [dataProvider]=\"tableProvider\"></crm-table>\r\n    </div>\r\n  </crm-general-content-body>\r\n</crm-general>\r\n"
    }]
  }], null, {
    tableProvider: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
/**
 * @deprecated
 */
class CrmListModule {
  static {
    this.ɵfac = function CrmListModule_Factory(t) {
      return new (t || CrmListModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmListModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CrmListComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmListModule, [{
    type: NgModule,
    args: [{
      imports: [CrmListComponent],
      exports: [CrmListComponent]
    }]
  }], null, null);
})();
class CrmListStateService extends CrmCompositeStateService {
  constructor(options) {
    super({
      initialState: {
        loading: true,
        config: {
          header: {
            title: 'List component'
          }
        },
        data: {}
      },
      ...options
    });
  }
}
class CrmListProvider extends CrmCompositeProvider {
  get tableProvider() {
    return this._tableProvider;
  }
  onInitFromRouteData(routeParams, routeData) {
    super.onInitFromRouteData(routeParams, routeData);
    try {
      this._tableProvider = this.injector.get(routeData.tableDataProvider);
    } catch {
      throw new Error(`Undefined 'tableDataProvider' in route data for '${this.constructor.name}'`);
    }
  }
  getStateInstance() {
    return new CrmListStateService(this.getStateServiceConfig());
  }
  getComponentConfig(_params) {
    return {
      header: {
        title: 'List component'
      }
    };
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmListProvider_BaseFactory;
      return function CrmListProvider_Factory(t) {
        return (ɵCrmListProvider_BaseFactory || (ɵCrmListProvider_BaseFactory = i0.ɵɵgetInheritedFactory(CrmListProvider)))(t || CrmListProvider);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmListProvider,
      factory: CrmListProvider.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmListProvider, [{
    type: Injectable
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmListComponent, CrmListModule, CrmListProvider, CrmListStateService, crmFillIn, crmPopulateResponse, crmSafeListByIDs };
